import React from 'react';
import { HeaderTitle, StyledLink, Sentence } from 'src/components/Typography';
import StickyBox from 'react-sticky-box/dist/esnext';
import { useMediaQueries } from '../../utils/MediaQueries';

const StickyBoxFAQ = () => {
  const mqs = useMediaQueries();

  const content = () => (
    <div>
      {mqs.phoneOnly ? (
        <HeaderTitle
          css={{
            marginBottom: 15,
            paddingTop: 50,
          }}
        >
          Questions fréquentes
        </HeaderTitle>
      ) : (
        <HeaderTitle
          css={{
            marginBottom: 30,
            paddingTop: 60,
          }}
        >
          Questions <br />
          fréquentes
        </HeaderTitle>
      )}

      <Sentence
        css={{
          fontWeight: 'bold',
          fontSize: 17,
          marginBottom: 20,
        }}
      >
        Dernière mise à jour :<br />
        le 24/05/2024
      </Sentence>
      <Sentence>
        Une question ne figure pas dans cette liste ?
        <br />
        <br />
        Contactez-nous <StyledLink to="/contact/">par mail</StyledLink>.
      </Sentence>
    </div>
  );

  if (mqs.tabletLandscapeUp || mqs.desktopUp || mqs.bigDesktopUp) {
    return <StickyBox offsetTop={100}>{content()}</StickyBox>;
  } else {
    return <div>{content()}</div>;
  }
};

export default StickyBoxFAQ;
